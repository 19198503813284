.projectContent {
  background-color: #000000;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.pointsContainer {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 20px;
  top: 175px;
  height: 140px;
  right: 25px;
  transform: translateY(-50%) !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.projectPoint {
  width: 10px;
  height: 10px;
  padding: 6px;
  box-sizing: border-box;
  margin: 5px 0;
  background-color: white;
  border-radius: 50%;
  transition: 1s;
}

.projectPoint.active {
  background-color: #00b4d8;
  height: 20px;
  border-radius: 2px;
  animation-name: in-and-stay;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes in-and-stay {
  from {
    height: 20px;
    border-radius: 2px;
  }
  to {
    height: 10px;
    border-radius: 50%;
  }
}

.activeImageHadChanged {
  animation-name: out-and-back;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes out-and-back {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.projectList {
  list-style: none;
  margin: 0 10px;
  margin-block-start: auto;
  padding: 0;
  max-width: 350px;
  font-size: 14px;
}

.projectContent h2 {
  text-align: center;
  margin: 10px 0;
  font-size: 22px;
}

.projectList li {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #00b4d8;
}

.projectList li span {
  text-align: right;
  width: 70%;
  font-weight: 400;
  color: #fff;
}

.projectInformation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectInformation img {
  height: max-content;
  padding: 25px;
}

.projectListImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.borderLeft,
.borderRight {
  width: 1px;
  height: 50px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
}

.borderLeftBottom,
.borderRightBottom {
  height: 1px;
  width: 100px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 2px;
}

.borderRight {
  left: initial;
  right: 0;
}

.borderRightBottom {
  left: initial;
  right: 2px;
}

.projectItem {
  overflow: hidden;
}

.isCardExpanded {
  position: absolute !important;
  max-width: 600px !important;
  width: 100% !important;
  height: 86vh !important;
  max-height: 630px !important;
  padding: 10px;
  box-sizing: border-box;
}

.isCardCollapsed {
  position: relative;
  height: 200px;
}

.isImageExpanded {
  width: 100% !important;
}

.isImageCollapsed {
  height: 200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) !important;
}

.contentContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.react-magic-motion-placeholder-box {
  display: none !important;
}

.react-magic-motion-background-fade {
  position: absolute !important;
}

@media screen and (max-width: 650px) {
  .projectInformation {
    flex-wrap: wrap;
  }

  .pointsContainer {
    top: 100px;
    right: 20px;
  }

  .projectContent h2 {
    margin: 20px 0;
  }

  .projectListImgContainer {
    display: none;
  }

  .projectList {
    max-width: 100%;
  }

  .imgContainer {
    width: 350px;
    margin: auto;
    height: 175px;
  }

  .imgContainer img {
    max-width: 350px;
  }

  .isCardExpanded {
    max-width: initial !important;
    width: min(40rem, 95%) !important;
    height: calc(100% - 10rem) !important;
    max-height: initial !important;
  }
}
