.contactContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 2;
}

.contactInfo {
  display: flex;
  max-width: 500px;
  width: 100%;
  justify-content: space-around;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
}

.contactInfo .phone,
.contactInfo .email {
  width: 50%;
}

.contactInfo svg {
  width: 48px;
  height: 48px;
}

.contactInfo p {
  margin: 0;
  padding: 0;
}

.contactInfo::after {
  content: "";
  width: 2px;
  background-color: #fff;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.form {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
}

.form label {
  width: 250px;
  box-sizing: border-box;
  text-align: center;
  cursor: none;
}

.form label span {
  display: block;
  margin-bottom: 5px;
  margin-top: 20px;
}

.form input {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #fff;
  width: calc(100% - 10px);
  cursor: none;
}

.form .message {
  width: 100%;
}

.form textarea {
  min-height: 80px;
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 10px;
  box-sizing: border-box;
  width: calc(100% - 10px);
  cursor: none;
}

.form .submit {
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  padding: 10px 40px;
  border-radius: 10px;
  margin: auto;
  margin-top: 20px;
  cursor: none;
  transition: all 0.3s;
}

.form .submit:hover {
  border-color: #00b4d8;
  color: #00b4d8;
}

.loader {
  margin: 0 auto;
  padding-top: 17px;
}

.loader svg path,
.loader svg rect {
  fill: #00b4d8;
}

.thanksContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}

.thanksContainer p {
  display: block;
}

.thanksContainer .circle {
  position: absolute;
  left: 0;
  top: 0;
}

.thanks {
  text-align: center;
}

.phoneMessage,
.emailMessage {
  display: none;
}

.mb-10 {
  margin-bottom: 10px;
}

@media screen and (max-width: 495px) {
  .subject,
  .lastName {
    display: none;
  }
  .form label {
    width: 300px;
  }
  .form,
  .form .message {
    width: 300px;
  }
  .contactInfo svg {
    width: 30px;
    height: 30px;
  }
  .contactInfo .data {
    display: none;
  }
  .contactInfo .email,
  .contactInfo .phone {
    position: relative;
  }
  .phoneMessage,
  .emailMessage {
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}
