.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.footer .btn {
  color: #ffffff;
  margin-left: 30px;
  margin-bottom: 20px;
  background-color: transparent;
  font-size: 18px;
  cursor: none;
  border: none;
  text-transform: capitalize;
}

.footer .selected {
  color: #00b4d8;
  font-weight: bold;
}

.magic {
  position: relative;
  transform: translateY(3px);
}

.magicTab {
  width: 100%;
  height: 0.15rem;
  background-color: white;
  position: absolute;
}

@media screen and (max-width: 975px) {
  .footer .btn {
    margin-left: auto;
    margin-right: auto;
  }
}
