.workContainer {
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
}

.workContent {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  justify-content: space-around;
  align-items: center;
}

.leftContainer {
  color: #ffffff;
  position: relative;
  min-width: 300px;
}

.titleContainer {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 100px));
}

.titleContainer h2 {
  font-size: 72px;
  margin: 0;
  position: relative;
}

.titleContainer h2::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 4px;
  background-color: #ffffff;
  bottom: -5px;
  left: 10px;
  transition: 1s;
}

.titleContainer p {
  margin: 20px 0 0 10px;
}

@media screen and (max-width: 795px) {
  .titleContainer,
  .leftContainer {
    display: none;
  }
}
