.center,
.homeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.homeContainer {
  width: 100vw;
  position: relative;
  z-index: 2;
}

.center {
  max-width: 300px;
  max-height: 300px;
  position: relative;
}

.center::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 300px);
  height: calc(100% + 300px);
  border-radius: 50%;

  animation: orbit 10s linear infinite;
}

@keyframes orbit {
  0% {
    box-shadow: 2px 2px 0px 2px rgba(127, 127, 127, 0.7);
  }
  25% {
    box-shadow: 2px 2px 7px 3px rgba(127, 127, 127, 0.7);
  }
  50% {
    box-shadow: 2px 2px 15px 5px rgba(127, 127, 127, 0.7);
  }
  75% {
    box-shadow: 2px 2px 7px 3px rgba(127, 127, 127, 0.7);
  }
  100% {
    box-shadow: 2px 2px 0px 2px rgba(127, 127, 127, 0.7);
  }
}

.center img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%
  );
}

.moon {
  position: absolute;
  top: 50%;
  width: 350px;
  height: 350px;
  transform: translateY(-50%);
}

.moon .ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  border-radius: 50%;
  border: 10px solid #ffffff;
  transition: 0.3s;
}

.moon:hover .ring {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
}

.left {
  left: -175px;
}

.right {
  right: -175px;
}

.curve {
  background: transparent;
  position: absolute;
  right: -204px;
  top: 35px;
  z-index: 2;
}

.curve path {
  stroke-width: 10;
  stroke: #ffffff;
  stroke-linecap: round;
  fill: none;
}

.curve path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: stroke-dashoffset 2s ease-in-out;
}

.information {
  position: absolute;
  left: -300px;
  color: white;
  top: 10px;
}

.hello {
  font-size: 72px;
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  margin-left: -5px;
}

.hello::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 4px;
  background-color: #ffffff;
  bottom: -5px;
  left: 4px;
  transition: 1s;
}

.abilities {
  position: absolute;
  right: -335px;
  color: white;
  top: 50px;
  list-style: none;
  margin: 20px 0 0;
}

.abilities li {
  margin-bottom: 10px;
  transition: 1s;
}

.abilities li:nth-child(2) {
  margin-left: 5px;
}

.abilities li:nth-child(3) {
  margin-left: 8px;
}

.abilities li:nth-child(4) {
  margin-left: 10px;
}

.abilities li:last-child {
  margin-left: 6px;
}

.presentation {
  margin: 0;
}

.play {
  position: absolute;
  bottom: -75px;
  right: -175px;
}

.play svg {
  border: 3px solid #ffffff;
  border-radius: 50%;
  box-sizing: border-box;
  transition: 0.3s;
}

.play path {
  fill: #ffffff;
  transition: 0.3s;
}

.play:hover svg {
  background-color: #ffffff;
}

.play:hover path {
  fill: #000000;
}

.play .tooltip {
  color: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  right: -100px;
  opacity: 0;
  transition: 0.3s;
}

.play:hover .tooltip {
  opacity: 1;
}

.videoContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 0;
  transition: 0.5s;
  background-color: var(--joy-palette-background-backdrop);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  backdrop-filter: blur(8px);
  transition: opacity 400ms ease 0s, backdrop-filter 400ms ease 0s;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isNotHome .hello::after {
  width: 0;
}

.isNotHome .abilities li {
  opacity: 0;
}

.isNotHome .abilities li:nth-child(1n + 2) {
  margin-left: 0px;
  margin-top: -30px;
}

@media screen and (max-width: 1275px) {
  .moon {
    display: none;
  }
}

@media screen and (max-width: 975px) {
  .center::after {
    width: calc(100% + 150px);
    height: calc(100% + 150px);
  }
  .curve {
    right: -125px;
  }
  .abilities {
    right: -255px;
  }
  .information {
    left: -210px;
  }
  .play {
    bottom: -50px;
    right: -75px;
  }
}

@media screen and (max-width: 795px) {
  .center::after {
    width: 100%;
    height: 100%;
  }
  .curve,
  .abilities,
  .information,
  .play .tooltip {
    display: none;
  }
  .play {
    bottom: -50px;
    right: 25px;
  }
  .center {
    max-width: 200px;
    max-height: 200px;
    position: relative;
  }
}
