.app {
  background-color: black;
  width: 100vw;
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #ffffff;
  position: relative;
}

body,
button,
input,
select {
  font-family: "PT Sans", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Raleway", sans-serif;
}

.cursor {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background: #00000040;
  border-radius: 50%;
  z-index: 10;
}

.content {
  width: 300vw;
  position: relative;
  transition: 1s;
  display: flex;
  height: 100vh;
}

.contact {
  transform: translateX(0);
}

.home {
  transform: translateX(-100vw);
}

.work {
  transform: translateX(-200vw);
}

.modalIsOpen {
  z-index: 2;
}

@media screen and (max-width: 975px) {
  .cursor {
    display: none;
  }
  .footer .btn {
    margin-left: auto;
    margin-right: auto;
  }
}
