.projects {
  display: grid;
  gap: 7.5px;
  width: 645px;
  max-height: 420px;
  height: 100%;
  grid-template-columns: auto auto;
  overflow: hidden;
}

.arrow {
  display: none;
}

@media screen and (max-width: 795px) {
  .projects {
    max-height: 362px;
  }
}

@media screen and (max-width: 650px) {
  .projects {
    max-height: 420px;
    grid-template-columns: auto;
    width: 315px;
    overflow: hidden;
    gap: 15.5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .positionMobile {
    position: relative;
  }

  .arrow {
    width: 100%;
    height: 35px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    opacity: 0;
  }

  .up {
    top: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    animation-name: fade-in;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  .down {
    top: calc(100% - 30px);
    animation-name: fade-in2;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-in2 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .up svg {
    transform: rotate(180deg);
  }

  .displayNone {
    display: none;
  }

  .projectsUp .projectItem {
    transform: translateY(-430px) !important;
    transition: 1s all;
  }
}
